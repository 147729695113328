import React, { useContext, useRef } from 'react'
import classes from './scrollable.module.css';

import { _ } from '../../Constants';
import { getTouchesInStage, IsHtml } from '../../utils/utils';
import { StageContext } from '../../utils/StageContext';
import { useEffect } from 'react';

const Scrollable = ({children, className, noScrolledEvent}) => {

  const {stage_id} = useContext(StageContext);
  const startScroll = useRef({pos:0,scroll:0}); 
  const scrollRef = useRef();
  
  useEffect(()=>{
    udpateScroll();
    setTimeout(() => {
      udpateScroll()
    }, 100);

  },[scrollRef?.current?.scrollHeight, scrollRef?.current?.clientHeight])

  const touchStarted =(e)=>{
    const touches = getTouchesInStage(e.touches, stage_id);
    if(touches.length==1){
      startScroll.current = {
        startPos:touches[0].y, 
        scroll:scrollRef.current.scrollTop, 
        ts:new Date().getTime(), 
        lastPos: {x:touches[0].x, y:touches[0].y}
      };
    }
  }

  const touchMoved = (e) =>{
    const touches = getTouchesInStage(e.touches, stage_id);
    if(touches.length == 0){ 
      touchEnded();
    }else if(touches.length == 1){
      scrollRef.current.scrollTop = startScroll.current.scroll - (touches[0].y-startScroll.current.startPos)    
      startScroll.current.lastPos =  {x:touches[0].x, y:touches[0].y}; 
      
      udpateScroll()
    }
  }

  const touchEnded =(e)=>{
    if(new Date().getTime()-startScroll.current.ts>200)
      return;
    if(Math.abs(startScroll.current.startPos-startScroll.current.lastPos.y)>5)
      return;
    if(noScrolledEvent){
      const el = document.elementFromPoint(startScroll.current.lastPos.x, startScroll.current.lastPos.y);
      
      noScrolledEvent(startScroll.current.lastPos, el.dataset?.id);   
    }
  }

  const wheel = (e)=>{
    scrollRef.current.scrollTop = (scrollRef.current.scrollTop + e.deltaY * .2)
    udpateScroll()
  }

  const udpateScroll = ()=>{
    if(!scrollRef.current)
      return;
    let percent = scrollRef.current.scrollTop/(scrollRef.current.scrollHeight - scrollRef.current.clientHeight);
    let ratio = scrollRef.current.clientHeight / scrollRef.current.scrollHeight;
    //console.log(scrollRef.current.scrollHeight, percent, scrollRef.current.clientHeight)
    scrollRef.current.style.setProperty('--progress', percent);
    scrollRef.current.style.setProperty('--ratio', ratio);

    
    if(ratio>=1){
      scrollRef.current.classList.add(classes.hidden);  
    }else{
      scrollRef.current.classList.remove(classes.hidden);  
    }

  }

  if(IsHtml(children))
    return (
      <div className={classes.root+(className?" "+className:"")} dangerouslySetInnerHTML={{__html:children}}
        ref={scrollRef}
        onTouchEnd={touchEnded} onTouchStart={touchStarted}  onTouchMove={touchMoved} onWheel={wheel}
      >
        <div className={classes.scrollbar}><div className={classes.track}></div><div className={classes.thumb}></div></div>
      </div>
    )
  return (
    <div className={classes.root+(className?" "+className:"")}
      ref={scrollRef}
      onTouchEnd={touchEnded} onTouchStart={touchStarted}  onTouchMove={touchMoved} onWheel={wheel}
    >
      {children}
      <div className={classes.scrollbar}><div className={classes.track}></div><div className={classes.thumb}></div></div>
    </div>
  );
}

export default Scrollable;
