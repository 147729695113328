import React, {useContext, useRef, useState} from 'react'
import classes from './popup.module.css';

import { StageContext } from '../../../utils/StageContext';
import { LanguageContext} from '../../../utils/LanguageContext';

import ico_close from './../../../images/icons/ico_close.png'
import Text from '../../text/text';
import { RouterContext } from '../../../utils/RouterContext';
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { getTouchesInStage } from '../../../utils/utils';
import Scrollable from '../../scrollable/scrollable';
import { _ } from '../../../Constants';

const Popup = ({popup}) => {
  const {route, SetRoute} = useContext(RouterContext);
  const [page, setPage] = useState(0);
  const [sub, setSub] = useState(null);
  const {language} = useContext(LanguageContext);
  
  useEffect(()=>{setPage(0)},[popup]);

  const closeClicked =(e)=>{
    e.preventDefault();
    e.stopPropagation();
    if(window.isTouch && e.touches == null)
      return;
    if(sub != null)
      setSub(null);
    else
      SetRoute(route.substr(0, route.indexOf("/popup")))    
  }

  const imgClicked =(e, objUuid)=>{
    if(window.isTouch && e.touches == null)
      return;
    e.preventDefault();
    e.stopPropagation();

    if(hasNextPage && actualPopup.subpopups && actualPopup.subpopups[objUuid]){
      setSub(actualPopup.subpopups[objUuid]);
    }
    //if(hasNextPage)
     // setPage(page+1);
  }
  
  const noScrolledEvent = (pos, objUuid)=>{
    if(hasNextPage && actualPopup.subpopups && actualPopup.subpopups[objUuid]){
      setSub(actualPopup.subpopups[objUuid]);
    }
  }

  if(popup == null || popup?.popups?.length===0)
    return(null);
  
  const actualPopup = popup?.popups[0];
  const hasNextPage = sub == null && actualPopup?.subpopups!=null && Object.keys(actualPopup?.subpopups).length>0;
  //console.log(sub, actualPopup, actualPopup.subpopups?.length)
  let rows = actualPopup?.rows;
  if(sub)
    rows = sub;
  
  return (
    <div className={classes.root+_+(actualPopup.bg && page === 0?classes.animated:_)}>
      {actualPopup.bg && <div className={classes.bg}>
        <img src={actualPopup.bg.Get(language)} alt={actualPopup.bg.Get(language)}></img>
      </div>}
      <div className={classes.container}>
        {actualPopup?.title && <h1><Text>{actualPopup?.title.Get(language)}</Text></h1>}
          <Scrollable className={classes.scrollable} noScrolledEvent={noScrolledEvent}>
            <div className={classes.scrollableChildren}>
              {rows.map((row, id)=>{
                return(<div key={id} className={classes.row}>
                  {row.type === "text"?
                    <Text className={classes.text}>{row.Get(language)}</Text>
                  :(
                    row.Get(language).indexOf(".mp4")>-1?
                      <video src={row.Get(language)} alt={row.Get(language)} autoPlay={true} muted={true} />
                    :
                      <img src={row.Get(language)} alt={row.Get(language)} data-id={row.uuid} onMouseUp={(e)=>imgClicked(e,row.uuid)} />
                  )
                  }
                </div>);
              })
              }
            </div>
          </Scrollable>
        <img src={ico_close} className={classes.close} alt="img" onMouseUp={closeClicked} onTouchEnd={closeClicked}></img>
      </div>
    </div>
  );
}

export default Popup