import React, {useEffect, useContext, createContext} from 'react'
import classes from './idle.module.css';

import { StageContext } from '../../utils/StageContext';
import { AppContext} from '../../utils/AppContext';

import { _ } from '../../Constants';

import { useState } from 'react';
import { GetPath } from '../../utils/utils';
import bg from './idle_bg.svg'
import { RouterContext } from '../../utils/RouterContext';

const IdleView = () => {
  const appCtx = useContext(AppContext);
  const data = appCtx.db?.idle;
  
  const [lastState, setLastState] = useState(null);
  const {route} = useContext(RouterContext);
  
  useEffect(()=> {
    setLastState(route);
  }, [route])

  if(route.indexOf("/idle") === -1){
    return(null)
  }
  
  return (
    <div className={"fullscreen "+classes.root}>
      <img className={classes.tapIcon} src={GetPath(data.icon)}></img>
      {
        data.images.map((img, id)=>{
          return(<div key={id} className={classes.img} style={{"--x":img.pos.x, "--y":img.pos.y, "--angle":img.angle,backgroundImage:"url("+bg+")"}} ><img src={GetPath(img.file)}></img></div>)
        })
      }
    </div>
  );
}
export default IdleView;