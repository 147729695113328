import React, { createContext, useState, useEffect, useCallback } from "react";
import { useTranslation } from 'react-i18next';

// create context
const LanguageContext = createContext();

const LanguageContextProvider = ({lang, children }) => {
  const [language, _setLanguage] = useState(null);
  const [{t}, _setTranslation] = useState(useTranslation());

  const setLanguage = useCallback((lang) => {
    _setLanguage(lang);
  }, []);

  const Get = (key) => {    
    return t(key, {lng:language});  
  };

  useEffect(() => {
    if(language != lang)
      _setLanguage(lang);
   }, []);

  return (
    <LanguageContext.Provider value={{language, setLanguage, get:Get}}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageContextProvider };
