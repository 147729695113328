import React, {useContext} from 'react'
import classes from './stage.module.css';

import { StageContext, States } from '../utils/StageContext';
import { _ } from '../Constants';
import { LanguageContext} from '../utils/LanguageContext';
import Footer from './footer/footer';
import Content from './content/content';
import { SettingsContext } from '../utils/SettingsContext';
import { useEffect } from 'react';
import { RouterContext, Routes } from '../utils/RouterContext';

const idleTimeOuts = {};

const Stage = () => {
  const {stage_id} = useContext(StageContext);
  const {route, SetRoute, IsRoute} = useContext(RouterContext);
  const {language, setLanguage} = useContext(LanguageContext);
  const settings = useContext(SettingsContext);
  
  const createTimeOut = (forceRestart = false)=>{
    if(idleTimeOuts[stage_id])
      clearTimeout(idleTimeOuts[stage_id]);
    if(forceRestart || !IsRoute(Routes.idle) )
      idleTimeOuts[stage_id] = setTimeout(()=>{ setLanguage('de');SetRoute(Routes.idle);}, settings.state.timeout*1000);
  }

  const interactionFound=(evt)=>{
    if(!window.isTouch)
      window.isTouch = evt.touches!=null
    
    createTimeOut(true);
    if(IsRoute(Routes.idle) || route === "")
      SetRoute(Routes.intro);
  }
 
 

  useEffect(()=>{
    let stageRoutes = window.location.hash?.split("#");
    
    if(stageRoutes.length !== 3){
      stageRoutes = ["","",""];
    }
    if(stageRoutes.length === 3){
      if(stage_id === "left")
        stageRoutes[1] = route;
      else if(stage_id === "right")
        stageRoutes[2] = route;
     
      window.history.pushState('route', 'Title', "#"+stageRoutes[1] +"#"+stageRoutes[2] );
    }
    if(route === "")
      SetRoute(Routes.idle)
  },[route]);

  if(!stage_id || !language)
    return("loading")
  
  createTimeOut();
    
  return (
    <div className={classes.root} onMouseDown={(e)=>interactionFound(e)} onTouchStart={(e)=>interactionFound(e)}>      
      <Content></Content>
      <Footer></Footer>      
    </div>
  );
}

export default Stage;
