import axios from "axios";
import React, { createContext, useState, useEffect, useCallback } from "react";


const SettingsContext = createContext();

const SettingsContextProvider = ({children }) => {
  const [state, setState] = useState({});

  const [loaded, setLoaded] = useState(false);

  const load = ()=>{
    axios.get("/settings.json")
    .then(function (res) {
      if(!state.loaded){
        res.data.loaded = true;
       // console.log(res.data)
        setState({...res.data, loaded:true});
      }
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      
    });  
  };

  useEffect(() => {
    if(!loaded){
      load();
      setLoaded(true);
    }
  });


    
  return (
    <SettingsContext.Provider value={{state}}>
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsContext, SettingsContextProvider };
