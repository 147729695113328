import React from 'react'
import classes from './text.module.css';

import { _ } from '../../Constants';
import { IsHtml } from '../../utils/utils';

const Text = ({children, className}) => {
  
  if(typeof children !== "string")
    return children;
  if(IsHtml(children))
    return (
      <div className={classes.root+(className?" "+className:"")} dangerouslySetInnerHTML={{__html:children}}></div>
    )
  return (
    <div className={classes.root+(className?" "+className:"")}>
      {children}
    </div>
  );
}

export default Text;
