import classes from './footer_details.module.css';
import { _ } from '../../Constants';
import Scrollable from '../scrollable/scrollable';


const FooterDetails = ({children, opened, detailsStyle, className, type}) => {
  //console.log(opened)

  return (
    <div className={classes.details + _ + (className?_+className:"")+ _ + (opened?classes.active:classes.unactive)}>
      <div className={classes.stripe}></div>
      <div className={classes.openedContent}>
        {type === "language" || type === "menu"?
          opened && children
        :
          opened &&  
          <div className={classes.dynamic} style={detailsStyle}>
            <Scrollable  className={classes.scrollable}>
              {children}           
            </Scrollable>
          </div> 
        }
      </div>
    </div>
  );
}

export default FooterDetails;
