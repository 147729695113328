
import React, {useEffect, useState} from 'react'
import { _ } from '../../Constants';
import classes from './img.module.css';
const Image = ({src, transition=false, className=null}) => {
  const [loaded, setLoaded] = useState(null);
  const [lastSrc, setLastSrc] = useState(null);
  const [transitionImageId, setTransitionImageId] = useState(0);
  
  useEffect(() => {
    if(transition){
      setTransitionImageId(transitionImageId===0?1:0);
      setLastSrc(loaded?.src);
    }
    setLoaded({src:src, loaded:false})
  }, [src]);
  
  if(transition){
    return (
      <>
      <div className={classes.root + _ +(className?className:_)}>
        <img className={(transitionImageId === 0?classes.active:classes.unactive)} src={transitionImageId === 0?src:lastSrc} />
        <img className={(transitionImageId === 1?classes.active:classes.unactive)} src={transitionImageId === 1?src:lastSrc} />
        </div>
      </>
    );
  }
  
  return (
    <img
        className={classes.root + _ + (loaded?classes.loaded:classes.loaded) + _ + (className?className:_)}
        src={src}        
      />
  );
}
export default Image;
