import i18n from "i18next";
import { initReactI18next } from "react-i18next";


i18n
  .use(initReactI18next)
  .init({
    debug:true,
    resources: {
      fr: {
        translation: {
          //Fran translations here
        },
      },
      pl: {
        translation: {
          //Polen translations here
        },
      },
      
      de: {
        translation: {
          "de":"DE",
          "en":"EN",
          "fr":"FR",
          "pl":"PL",
          "menu":"Menü",
          "hello":"Hallo",
          "help":"Bedienung",
          "admin":{
            "title":"In den Führungsmodus wechseln?",
            "text":"Bitte geben Sie die PIN ein."
          },
          
        },
      },
      en: {
        translation: {
          "hello":"Hello",
          "admin":{
            "title":"Switch to guided tour mode?",
            "text":"Please enter the PIN code."
          }
        },
      },
      fr: {
        translation: {
          "hello":"Bonjour",
          "admin":{
            "title":"Passer en mode guidage?",
            "text":"Veuillez saisir le code PIN."
          }
        },
      },
      pl: {
        translation: {
          "hello":"Dzień dobry",
          "admin":{
            "title":"Przełączyć na tryb przewodnika?",
            "text":"Proszę wprowadzić kod PIN."
          }
        },
      },
    },
    lng: "en",
    fallbackLng: "de",
  });

export default i18n;