import React, {useContext, useEffect} from 'react'
import classes from './intro.module.css';

import { StageContext, States } from '../../utils/StageContext';

import { LanguageContext} from '../../utils/LanguageContext';
import { AppContext} from '../../utils/AppContext';
import { useState } from 'react';
import { GetPath, getTouchesInStage } from '../../utils/utils';
import Text from '../text/text';
import { MapContext } from '../../utils/MapContext';
import { useRef } from 'react';
import { RouterContext, Routes } from '../../utils/RouterContext';
import { _ } from '../../Constants';

const IntroView = ({autoplay}) => {
  const isMounted = useRef(false)
  const timeout = useRef(false)
  const moveTimeout = useRef(false)
  const appCtx = useContext(AppContext);
  const data = appCtx.db?.intro;
  const [progressWidth, SetProgressWidth] = useState(1);
  const {SetOpenedLegend, stage_id} = useContext(StageContext);
  const {route, SetRoute, Get, IsRoute} = useContext(RouterContext);
  const {SetMapObject} = useContext(MapContext);
  
  const {language} = useContext(LanguageContext);
  const ref = useRef();
  const progressRef = useRef();
  const labelRef = useRef();

  let actualEntry = 0;
  if(Get(1)!=null)
    actualEntry = parseInt(Get(1));
  else{
    if(IsRoute(Routes.home))
      actualEntry = data.years?.length-1;
  }
  
  let lastId = null;
  const getIdFromEvent = (e) =>{
    const touches = getTouchesInStage(e.touches, stage_id);
    
    if(touches.length === 0)  
      return lastId?lastId:0;

    const r = ref.current?.getBoundingClientRect();
    const b = 15;
    
    let x = touches[0]?.x - r.left;
    if(isNaN(x))
      return lastId;
    let percent = (x-b)/(r.width-b*2);
    percent = Math.min(1, Math.max(0,percent));
    let id = Math.round(percent*(data.years.length-1));
    if(id != null && !isNaN(id))
      lastId = id;
    
    return id;
  }


  const progressDone = (e)=>{
    let id = getIdFromEvent(e)
    ref.current.removeEventListener("touchmove", progressMoved);
    ref.current.removeEventListener("touchend", progressDone);
    clearTimeout(moveTimeout.current);
    SetRoute(Routes.home+"/"+id);
  }
  const progressMoved =(e)=>{
    let id = getIdFromEvent(e)
    clearTimeout(moveTimeout.current);
    progressRef.current.style.setProperty("--progress",id/(data.years.length-1));
    moveTimeout.current = setTimeout(()=>(SetRoute(Routes.home+"/"+id)), 1000);

    labelRef.current.innerHTML = data.years[id].year
    
  }

  const progressClicked = (e) =>{
    if(window.isTouch)
      return;

    const b = 15;
    let x = e.layerX;
    if(x == null)
      x = e.nativeEvent.layerX;
          
    let percent = (x-b)/(ref.current?.getBoundingClientRect().width-b*2);
    percent = Math.min(1, Math.max(0,percent));
    let id = Math.round(percent*(data.years.length-1));

    e.preventDefault();
    e.stopPropagation();
    clearTimeout(timeout.current);
    clearTimeout(moveTimeout.current);
    
    SetRoute(Routes.home+"/"+id);
  }
  const progressTouched = (e) =>{
    let id = getIdFromEvent(e)
    e.preventDefault();
    e.stopPropagation();
    clearTimeout(timeout.current);
    clearTimeout(moveTimeout.current);
    
    ref.current.removeEventListener("touchmove", progressMoved);
    ref.current.removeEventListener("touchend", progressDone);

    ref.current.addEventListener("touchmove", progressMoved);
    ref.current.addEventListener("touchend", progressDone);
    
    SetRoute(Routes.home+"/"+id);
  }


  const showNextYear = ()=>{
    if(!isMounted.current)
      return;
    let id = 0;
    if(actualEntry<data.years.length-1){
      id = actualEntry+1;
      SetRoute(Routes.intro+"/"+id);
    }else{
      //id = 0;
      //SetRoute(Routes.intro+"/"+id);
    }
  }

  useEffect(() => {
    isMounted.current = true;
    if(IsRoute(Routes.intro)){
      if(actualEntry === 0){
        const ev = new CustomEvent('footerEvent_'+stage_id, {'detail': {key:"legend", val:true}})
        document.dispatchEvent(ev);
      }else if(actualEntry>= data.years.length-1){
        const ev = new CustomEvent('footerEvent_'+stage_id, {'detail': {key:"home", val:true}})
        document.dispatchEvent(ev);
      }
    }
      
    if(progressWidth === 1 && ref.current!=null)
      SetProgressWidth(ref.current?.getBoundingClientRect().width);
    SetMapObject({img:GetPath(data.years[actualEntry].file), overlay:data.overlay.Get(language), legend:data.legend.Get(language), scalable:false, animation:null})
    clearTimeout(timeout.current);
    if(IsRoute(Routes.intro))
      timeout.current = setTimeout(showNextYear, 1000);
    return () => { isMounted.current = false }
  }, [route]);
  
  if(data.years?.length===0)
    return(0);

  return (
    <div className={"fullscreen "+classes.root}>      
      <div className={classes.progress + _ +(stage_id==="left"?classes.left:_)} onMouseDown={progressClicked} onTouchStart={progressTouched} ref={ref}>
        <div className={classes.label}  ref={labelRef}>{data.years[actualEntry].year}</div>
        <div className={classes.progressBorder} style={{"--progress":actualEntry/(data.years.length-1)}} ref={progressRef}>
          <div className={classes.thumb}></div>
        </div>
      </div>
      <div className={classes.title}>
          <Text>{data.title.Get(language, {'year':data.years[actualEntry].year})}</Text>
      </div>
    </div>
  );
}

export default IntroView;
