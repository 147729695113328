import React, {useEffect, useContext, createContext, useState} from 'react'
import classes from './admin.module.css';

import { StageContext, States } from '../../../utils/StageContext';
import { _ } from '../../../Constants';
import { LanguageContext} from '../../../utils/LanguageContext';
import { AppContext, States as AppStates} from '../../../utils/AppContext';
import AdminInput from './input/admin_input';
import ico_backspace from './../../../images/icons/key_backspace.png';
import ico_close from './../../../images/icons/ico_close.png'

const Admin = () => {
  const appCtx = useContext(AppContext);
  const appstate = appCtx.state;
  const SetAppState = appCtx.SetState;

  const {stage_id, state, SetState} = useContext(StageContext);
  const {language, get} = useContext(LanguageContext);


  const [selected, setSelected] = useState(0);
  const [chars, setChars] = useState(["","","","",""]);

  if(appstate === AppStates.kiosk)
    return(null);


  const clicked = (e)=>{
    if(window.isTouch && e.touches == null)
      return;
    let val = e.target?.innerHTML 
    if(e.target.classList.contains("backspace")){
      const lastEmpty = chars[chars.length-1] === "";
      chars[Math.max(0,selected)] = "";
      if(selected<chars.length-1 || lastEmpty){
        chars[Math.max(0,selected-1)] = "";
        setSelected(Math.max(0,selected-1))
      }
      setChars([...chars]);
    }
    else if(selected<=chars.length-1){
      chars[selected] = val;

      if(chars.join("") === "12345"){
        SetAppState(AppStates.admin);
        setChars(["","","","",""]);
        setSelected(0);
      }else{
        setChars([...chars]);
        setSelected(Math.min(chars.length-1, selected+1))
      }
    }
    
  }

  return (
    <div className={classes.root+_+stage_id+_+appstate}>
      {stage_id === "left" && appstate === AppStates.admin &&
        <img className={classes.overlay} src="/content/admin_overlay.jpg" alt="admin"></img>
      }
      {stage_id === "right" && appstate === AppStates.admin_login &&
        <div className={classes.admiLogin}>
          <div className={classes.top}>
            <div className={classes.close} 
              onMouseDown={()=>{SetAppState(AppStates.kiosk)}} 
              onTouchEnd={()=>{SetAppState(AppStates.kiosk)}}
              style={{backgroundImage:'url('+ico_close+')'}}>              
            </div>
            <h1>{get("admin.title")}</h1>
            <h2>{get("admin.text")}</h2>
            <AdminInput selectedHandler={setSelected} chars={chars} selectPosition={selected}></AdminInput>
          </div>
          <div className={classes.bottom}>
            <div className={classes.btn} onMouseDown={clicked} onTouchEnd={clicked}>1</div>
            <div className={classes.btn} onMouseDown={clicked} onTouchEnd={clicked}>2</div>
            <div className={classes.btn} onMouseDown={clicked} onTouchEnd={clicked}>3</div>
            <div className={classes.btn} onMouseDown={clicked} onTouchEnd={clicked}>4</div>
            <div className={classes.btn} onMouseDown={clicked} onTouchEnd={clicked}>5</div>
            <div className={classes.btn} onMouseDown={clicked} onTouchEnd={clicked}>6</div>
            <div className={classes.btn} onMouseDown={clicked} onTouchEnd={clicked}>7</div>
            <div className={classes.btn} onMouseDown={clicked} onTouchEnd={clicked}>8</div>
            <div className={classes.btn} onMouseDown={clicked} onTouchEnd={clicked}>9</div>
            <div className={classes.btn} onMouseDown={clicked} onTouchEnd={clicked}></div>
            <div className={classes.btn} onMouseDown={clicked} onTouchEnd={clicked}>0</div>
            <div className={classes.btn + _ + "backspace"} onMouseDown={clicked} onTouchEnd={clicked}><img src={ico_backspace} style={{pointerEvents:"none"}} alt="backspace" /></div>
            
          </div>
        </div>
      }
    </div>
  );
}

export default Admin;
