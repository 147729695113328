import React, {useEffect, useContext} from 'react'
import classes from './entry_view.module.css';

import { LanguageContext} from '../../../utils/LanguageContext';

import Text from '../../text/text';
import { MapContext } from '../../../utils/MapContext';
import { GetPath } from '../../../utils/utils';
import { _ } from '../../../Constants';
import ico_back from './../../../images/icons/ico_back.png'
import { StageContext } from '../../../utils/StageContext';
import { RouterContext } from '../../../utils/RouterContext';
import { AppContext } from '../../../utils/AppContext';

const EntryView = ({entry}) => {

  const db = useContext(AppContext).db;
  const {SetMapObject} = useContext(MapContext);
  const {language} = useContext(LanguageContext);
  //const {subEntry, SetSubEntry} = useContext(StageContext);
  const {route, SetRoute} = useContext(RouterContext);
 
  const goBack =(e)=>{
    if(window.isTouch && e.touches == null)
      return;
    let parts = route.split("/");
    if(parts.length>0)
      parts.splice(0,1);
    
    if(parts.length>1)
      parts.splice(1, parts.length-1);
    
    SetRoute("/"+parts.join("/"));
    //SetSubEntry(null)
  }
  useEffect(() => {
    if(entry != null){
       SetMapObject({img:GetPath(entry.map.Get(language)), overlay:entry.overlay?.Get(language), legend:entry.legend?.Get(language), scalable:false, animation:null})
    }
  }, [entry, language]);// eslint-disable-line react-hooks/exhaustive-deps
  
  let subEntry = db.GetSubByRoute(route);
 
  return (
    <div className={"fullscreen "+classes.root}>
      {subEntry && 
        <div className={classes.back}>
          <img src={ico_back} onMouseDown={(e)=>goBack(e)} onTouchEnd={(e)=>goBack(e)} alt="back" ></img>
        </div>      
      }
      <div className={classes.title}>
        <Text>{subEntry?subEntry.menuTitle.Get(language):entry?.menuTitle.Get(language)}</Text>
      </div>
    </div>
  );
}
export default EntryView;