
import classes from './App.module.css';
import { _ } from './Constants';
import Stage from './stage/stage';
import { AppContextProvider } from './utils/AppContext';
import { LanguageContextProvider } from './utils/LanguageContext'
import { MapContextProvider } from './utils/MapContext';
import { RouterContextProvider } from './utils/RouterContext';
import { SettingsContextProvider } from './utils/SettingsContext';

import {StageContextProvider } from "./utils/StageContext";
function App() {
  
  const rotated = false;
   
  return (
    <SettingsContextProvider>
      <AppContextProvider>
      <div className={classes.root+(rotated?(_+classes.rotated):_)}>
        <StageContextProvider id="left">
          <RouterContextProvider id="left">
            <LanguageContextProvider lang="de">
              <MapContextProvider>
                <Stage></Stage>
              </MapContextProvider>
            </LanguageContextProvider>
          </RouterContextProvider>
        </StageContextProvider>
        <StageContextProvider id="right">
          <RouterContextProvider id="right">
            <LanguageContextProvider lang="de">
              <MapContextProvider>
                <Stage></Stage>
              </MapContextProvider>
            </LanguageContextProvider>
          </RouterContextProvider>
        </StageContextProvider>
      </div>
      {false && <div className="tmp">
        <img src="/images/tmp/6.jpg" alt="tmpimg" />
      </div>
      }
      </AppContextProvider>
    </SettingsContextProvider>
  );
}

export default App;
