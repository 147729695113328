export const GetPath = (filePath)=>{

  if(filePath?.path)
    return "/content"+filePath?.path;
  
  return "/content"+filePath;
}

const htmlRegex = /<\/?[a-z][\s\S]*>/i
export function IsHtml(txt){
  
  return htmlRegex.test(txt);
}



const rects = {};
export const getTouchesInStage = (touches, stage_id)=>{
  
  if(rects[stage_id] == null){
    if(stage_id === "left")
      rects[stage_id] = {left:0,right:window.innerWidth/2,top:0};
    else if(stage_id === "right")
      rects[stage_id] = {left:window.innerWidth/2,right:window.innerWidth,top:0};
  }
  
  const ftouches = [];
  for(let i=0;i<touches.length;i++){
    if(touches[i].clientX>rects[stage_id].left && touches[i].clientX<rects[stage_id].right)
      ftouches.push({
        x: touches[i].clientX/* - rects[stage_id].left*/,
        y: touches[i].clientY - rects[stage_id].top,
      })
  }
  return ftouches;
}