import React, { createContext, useState, useCallback } from "react";

const MapContext = createContext();

const MapContextProvider = ({children }) => {
  
  const [state, setState] = useState({});
  
  
  const Set = useCallback(
    ({img, overlay=null, legend=null, scalable=false, animation=null}) => {
      //console.log(state, img, overlay)
     // if(state.img !== img && state.overlay !== overlay){
     //   console.log("set", img, overlay)
        setState({img:img, overlay:overlay, scalable:scalable, animation:animation, legend:legend});
      //}
    }
  , []);

  return (
    <MapContext.Provider value={{mapObject:state, SetMapObject:Set}}>
      {children}
    </MapContext.Provider>
  );
};

export { MapContext, MapContextProvider};
