import axios from "axios";
import React, { createContext, useState, useEffect, useCallback, useContext } from "react";
import { ParseDB } from "./DB";
import { SettingsContext } from "./SettingsContext";


const AppContext = createContext();

const AppContextProvider = ({children }) => {
  
  const settings = useContext(SettingsContext).state;
  const [state, setState] = useState(States.kiosk);
  const [db, setDB] = useState({});

  const SetState = useCallback((_state) => {setState(_state);}, []);

  useEffect(() => {
    if(settings.loaded != null && Object.keys(db).length === 0){
      load()
    }
  });

  const load = () => {
    axios.get(settings.content_path+"/db.json")
    .then(res => {
      if(res.data?.checksum != db.checksum){
        const db = ParseDB(res.data);
        setDB(db)
      }
    })
  };

  if(!db || Object.keys(db).length === 0)
    return(<AppContext.Provider value={{state, db, SetState}}></AppContext.Provider>)
  

  //console.log(db)


  return (
    <AppContext.Provider value={{state, db, SetState}}>
      {children}
    </AppContext.Provider>
  );
};

const States = {
  'kiosk':'kiosk',
  'admin':'admin',
  'admin_login':'admin_login'
}

export { AppContext, AppContextProvider, States };
