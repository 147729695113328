import React, {useEffect, useContext, createContext} from 'react'
import classes from './admin_input.module.css';

import { _ } from '../../../../Constants';

import { AppContext, States as AppStates} from '../../../../utils/AppContext';
import { useRef } from 'react';
import { useState } from 'react';

const AdminInput = ({chars = ["","","","",""], selectPosition=0, selectedHandler}) => {
  const appstate = useContext(AppContext).state;
  const ref_1 = useRef();
  const ref_2 = useRef();
  const ref_3 = useRef();
  const ref_4 = useRef();
  const ref_5 = useRef();

  if(appstate === AppStates.kiosk)
    return(null);

  const clicked = (e,val)=>{
    if(window.isTouch && e.touches == null)
      return;
    selectedHandler && selectedHandler(val);
  }

  return (
    <div className={classes.root}>
      <div ref={ref_1} className={classes.btn + _ + (selectPosition === 0 && classes.selected)} onMouseDown={(e)=>{clicked(e,0)}} onTouchEnd={(e)=>{clicked(e,0)}}>{chars[0]}</div>
      <div ref={ref_2} className={classes.btn + _ + (selectPosition === 1 && classes.selected)} onMouseDown={(e)=>{clicked(e,1)}} onTouchEnd={(e)=>{clicked(e,1)}}>{chars[1]}</div>
      <div ref={ref_3} className={classes.btn + _ + (selectPosition === 2 && classes.selected)} onMouseDown={(e)=>{clicked(e,2)}} onTouchEnd={(e)=>{clicked(e,2)}}>{chars[2]}</div>
      <div ref={ref_4} className={classes.btn + _ + (selectPosition === 3 && classes.selected)} onMouseDown={(e)=>{clicked(e,3)}} onTouchEnd={(e)=>{clicked(e,3)}}>{chars[3]}</div>
      <div ref={ref_5} className={classes.btn + _ + (selectPosition === 4 && classes.selected)} onMouseDown={(e)=>{clicked(e,4)}} onTouchEnd={(e)=>{clicked(e,4)}}>{chars[4]}</div>
    </div>
  );
}

export default AdminInput;
