import React, { createContext, useState, useCallback } from "react";

const Routes = {
  idle:"/idle",
  home:"/home",
  intro:"/intro",
}






const RouterContext = createContext();
const RouterContextProvider = ({id, children }) => {
  let initialRoutes = window.location.hash?.split("#"); 
  let initialRoute = ""
  if(initialRoutes.length === 3){    
    if(id==="left")
      initialRoute = initialRoutes[1];
    else if(id==="right")
      initialRoute = initialRoutes[2];
  }
  const [route, setRoute] = useState(initialRoute);
  const SetRoute = useCallback((val) => {setRoute(val);}, []);

  const IsRoute = (checkRoute) => {
    //console.log(route, checkRoute, route.indexOf(checkRoute))
    if(route.indexOf(checkRoute) === 0)
      return true;
    return false;
  }

 
  const Get = (pos) => {
    const parts =  route.split("/");
    //console.log(parts, pos)
    if(parts.length>=pos+2)
      return parts[pos+1];

    return null;
  }


  return (
    <RouterContext.Provider value={{route, SetRoute, IsRoute, Get}}>
      {children}
    </RouterContext.Provider>
  );
};

export { RouterContext, RouterContextProvider, Routes };
