import { DateTime } from "luxon";
import { GetPath, IsHtml } from "./utils";
import { v4 as uuidv4 } from 'uuid';

export function ParseDB(raw){
  let db = new DB(raw);

  return db;
}

class DB{

  constructor(raw){
    //console.log(raw)
    this.checksum = raw.checksum;
    
    raw.entries.forEach((mainEntry)=>{
     
      if(mainEntry.name === "00_idle")
        this.idle = parseIdle(mainEntry)
      else if(mainEntry.name === "00_help")
        this.help = parseHelp(mainEntry)
      else if(mainEntry.name === "00_imprint")
        this.imprint = parseImprint(mainEntry)
      else if(mainEntry.name === "00_intro")
        this.intro = parseIntro(mainEntry)
      else if(mainEntry.name === "00_maplinks"){
        this.mapLinks = parseMapLinks(mainEntry);
      }
      else if(mainEntry.name === "01_menu")
        this.entries = parseMenuEntries(mainEntry);
    })
    
    this.root = raw.root;
    this.created_at = DateTime.fromISO(raw.created_at);
    console.log(this)
  }

  GetMainEntryByRoute(route){
    for(let i in this.entries){
      if(route.indexOf(this.entries[i].id) === 1)
        return this.entries[i];
    }
    return null;
  }

  GetPopupByRoute(route){
    return this.findElement(route, "popup")
  }
  GetSubByRoute(route){
    return this.findElement(route, "sub")
  }
  findElement(route, type="popup"){
    if(route.indexOf("/"+type) === -1)
      return null;

    const parts = route.split("/");
    if(parts.length>0)
      parts.splice(0,1);
    if(parts.length === 0)
      return null;

    let main = null;
    for(let i in this.entries){
      if(parts[0] === this.entries[i].id){
        main = this.entries[i];
        break;
      }
    }
    
    if(!main)
      return null;
    if(parts.length<3)
      return null;

   
    let sub = null;
    for(let i in main.subentries){
      if(main.subentries[i].id === parts[2]){
        sub = main.subentries[i];
        if(type === "sub")
          return sub;
        break;
      }
    }
    if(parts[1] === "popup" && sub){
      return sub;      
    }

    if(parts[1] === "sub" && sub){
      if(parts.length<5)
        return null;
      
      for(let i in sub.entries){
        if(sub.entries[i].id === parts[4]){
          return sub.entries[i];
        }
      }
    }
    return null;
  }
}


function parseMapLinks(raw){
  
  let entries = [];
  for(let i in raw.children){
    
    let img = findByName(raw.children[i].children,"img");
    let settings = findByName(raw.children[i].children,"settings");
    if(img && settings){
      entries.push({img:img.path, settings:settings.content})
    }    
  }
  
  return entries;
}


function parseMenuEntries(raw){
  
  let entries = [];
  for(let i in raw.children){
    let entry = new MenuEntry(raw.children[i]);
    entries.push(entry);
  }
  return entries;
}

function parseIntro(raw){
  const intro = {
    legend: new LanguageEntry(),
    overlay: new LanguageEntry(),
    years:[],
    title:new LanguageEntry()
  }
  
  let legend  = parseLangEntry(findByName(raw.children, "legend"), "legend")
  let overlay  = parseLangEntry(findByName(raw.children, "overlay"), "overlay")
  let title  = parseLangEntry(findByName(raw.children, "title.json"))
  if(legend)
    intro.legend = legend;
  if(overlay)
    intro.overlay = overlay;
  if(title)
    intro.title = title;

  let years = findByName(raw.children, "years");
  if(years?.children){
    years?.children.forEach(fyear=>{
      intro.years.push({file:fyear, year:fyear.name.split(fyear.extension).join("")})
    })
  }
  return intro;
}

function parseIdle(raw){
  const idle = {
    images: [],
    icon: findByName(raw.children, "icon.png"),    
  }

  let data = findByName(raw.children, "data.json");
  data.content.entries.forEach(e=>{
    let media = findByName(raw.children, e.img);
    if(media)
      idle.images.push({pos:e.pos, file:media, angle:e.angle!=null?e.angle:getAngle(e.pos)})
  })
  return idle;
}

function parseHelp(raw){
  let l = parseLangEntry(raw, "help");
  //console.log(l)
  return l;
}

function parseImprint(raw){
  let l = parseLangEntry(raw, "imprint");
  return l;
}

function parseLangEntry(entry, base){
  if(entry?.type === "Directory"){
    let lentry = new LanguageEntry();
    lentry.type = LanguageEntry.Types.FILES;
    let contentType = null;
    entry.children.forEach(file=>{
      if(file.extension){
        let fbase = file.name.split(file.extension).join("");
        
        let parts = fbase.split("_");
        //if(base === "imprint")
        //  console.log(file.name, parts, fbase, base)
        if(parts[0] === base && file.extension?.length<1){//directory with lang files ([base+"_"+lang].*)
          let fileType = file.extension === ".txt" && file.content != null?"text":"binary";
          if(contentType == null)
            contentType = fileType;
          else if(contentType !== fileType)
            contentType = null;
            
          if(parts.length>1)
            lentry._add(parts[1], file);          
        }else if(LanguageEntry.Languages.includes(fbase)){//directory with lang files ([lang].*)
          
          let fileType = file.extension === ".txt" && file.content != null?"text":"binary";
          if(contentType == null)
            contentType = fileType;
          else if(contentType !== fileType)
            contentType = null;
          
          lentry._add(fbase, file);
        }else if(LanguageEntry.Languages.includes(parts[0])){//directory with lang files ([lang]_filename.*)
         
          let fileType = file.extension === ".txt" && file.content != null?"text":"binary";
          if(contentType == null)
            contentType = fileType;
          else if(contentType !== fileType)
            contentType = null;
         //if(base === "imprint")
         //   console.log(parts[0], file, fileType, contentType)
          lentry._add(parts[0], file);
        }
        else if(LanguageEntry.Languages.includes(parts[1]) && parts[0] === base){//"legend_[lang].*"
          let fileType = file.extension === ".txt" && file.content != null?"text":"binary";
          if(contentType == null)
            contentType = fileType;
          else if(contentType !== fileType)
            contentType = null;
          
          lentry._add(parts[1], file);
        }

      }
    })
    
    if(contentType === "text"){
      let lentry2 = new LanguageEntry();
      lentry2.type = LanguageEntry.Types.TEXT;
      for(let i in lentry.entries){
        lentry2._add(i, lentry.entries[i].content);
      }      
      return lentry2;
    }
    return lentry;
  }else if(entry?.content != null){
    
    let lentry = new LanguageEntry();
  
    lentry.type = LanguageEntry.Types.TEXT
    for(let i in entry.content){
      lentry._add(i, entry.content[i]);
    }
    return lentry;
  }

  if(entry == null)
    return null;
  let lentry = new LanguageEntry();
  lentry._add("de", entry);

  if(entry.type === "File")
    lentry.type = LanguageEntry.Types.FILES

   
  return lentry;
}


const findByName = (entries, name)=>{
  let found = [];
  
  for(let i in entries){
    if(entries[i].name === name)
      found.push(entries[i]);
    else if(entries[i].name.indexOf(name) === 0)
      found.push(entries[i]);
  }

  if(found.length>0)
    return found[0];

  return null;
}


const getAngle=(pos)=>{
  var center = {
    x: 0.5,
    y: 0.5
  };  
  return Math.atan2(center.y - pos.y, center.x - pos.x) * 180 / Math.PI;
}


const sortByName = (a,b)=>{
  return a.name.localeCompare(b.name);
}

class MenuEntry{
  constructor(raw){
    

    let title = parseLangEntry(findByName(raw.children, "title"),"title");
    let rawMenuTitle = findByName(raw.children, "menu_title");
    let menuTitle = parseLangEntry(rawMenuTitle,"menu_title");
    
    let map = findByName(raw.children, "map");
    let overlay = parseLangEntry(findByName(raw.children, "overlay"),"overlay");
    let legend = parseLangEntry(findByName(raw.children, "legend"),"legend");
    
    if(title)
      this.title = title;
    else{
      this.title = new LanguageEntry();
      this.title._add("de",  raw.name);
     
    }
    if(legend)
      this.legend = legend;

    this.id = raw.name.split("_")[0];
    this.uuid = uuidv4();
    this.menuTitle = menuTitle?menuTitle:this.title;
    this.overlay = overlay;
    this.map = new LanguageEntry();
    this.map._add("de", map)

    let msettings = findByName(raw.children, "map_settings");
    if(msettings)
      this.mapSettings = msettings.content;


    let entries = findByName(raw.children, "entries");
    this.subentries = [];
    if(entries){
      //console.log(entries)
     // let children = findByName(entries.children, "entries");
     // let pos = findByName(entries.children, "position");
      for(let i in entries.children){
        let subentry = new SubEntry(entries.children[i]);
        if(subentry)
          this.subentries.push(subentry);
        //console.log(subentry)
      }
      
    }
    
    //if(this.id === "07"){
     // console.log(this)
     
    //}
  }
}



class SubEntry{
  constructor(raw){
    this.id = raw.name.split("_")[0];
    this.uuid = uuidv4();

    let position = findByName(raw.children, "position");
    let title = findByName(raw.children, "title");
    let link = findByName(raw.children, "link");
    let menu_title = findByName(raw.children, "menu_title");
    let bg = findByName(raw.children, "bg");
    if(bg)
      this.bg = parseLangEntry(bg);

    if(position?.content) 
      position = position.content;
    if(link && link.content?.link){
      this.link = link.content.link
    }
    if(title)
      title = parseLangEntry(title); 
    
    if(menu_title)
      menu_title = parseLangEntry(menu_title);
    else
      menu_title = title;
    
    this.title = title;
    this.menuTitle = menu_title;
    this.position = position;

    let popups = findByName(raw.children, "popup");
    this.popups = [];
    if(popups){
      let popup = new PopUp(popups, this.bg);
      if(popup)
        this.popups.push(popup);
    }
    /*for(let i in popups?.children){
      let popup = new PopUp(popups.children[i], this.bg);
      if(popup)
        this.popups.push(popup);
    }*/
    
    let entries = findByName(raw.children, "entries");
    if(entries){
      this.entries = [];
      for(let i in entries?.children){
        let entry = new SubEntry(entries.children[i]);
        if(entry)
          this.entries.push(entry);
      }
    }
  }

  
}

class PopUp{
  constructor(raw, customBg=null){

    this.bg = customBg;
    this.id = raw.name.split("_")[0];
    this.uuid = uuidv4();
    this.rows = [];
    if(raw.children){
      raw.children.sort(sortByName);
      for(let i in raw.children){
        let row = parseLangEntry(raw.children[i]);        
        if(row){
          if(raw.children[i].name === "title.json")
            this.title = row;
          else if(raw.children[i].name === "link.json" && raw.children[i].content?.link)
            this.link = raw.children[i].content.link
          else
            this.rows.push(row);
        }
        
        if(raw.children[i].children && raw.children[i].children.length>0){
          for(let j in raw.children[i].children){
            if(raw.children[i].children[j].type === "Directory"){
              if(this.subpopups == null)
                this.subpopups = {};
              if(this.subpopups[row.uuid] == null)
                this.subpopups[row.uuid]  = [];
              this.subpopups[row.uuid].push(parseLangEntry(raw.children[i].children[j]));
           }
          }
        }
      }
    }
    
  }
}

class LanguageEntry{
  constructor(){
    if(LanguageEntry.Types == null)
    {
      LanguageEntry.Types = {
        TEXT:'text',
        FILES:'files'
      }
    }
    if(LanguageEntry.Languages == null)
      LanguageEntry.Languages = ['de',"en", "pl", "fr"];
    
    this.uuid = uuidv4();
    this.entries = {};
  }

  _add(lang, val){
    this.entries[lang] = val;
    if(IsHtml(val))
      this.isHtml = true;
  }


  Get(lang=null, options={}){
    if(this.entries.length === 0)
      return null;

    if(lang == null)
      lang = Object.keys(this.entries)[0];
    
    let found = ""
    if(this.entries[lang])
      found = this.entries[lang];
    else
      found = this.entries[Object.keys(this.entries)[0]];

    for(let i in options){
      found = found.split('{'+i+'}').join(options[i]);
    }

   // console.log(this.type)
    if(this.type ===  LanguageEntry.Types.FILES)
      return GetPath(found)
    
    return found
  }
}